<template>
  <div>
    <b-alert :variant="'primary'" show class="cursor-pointer">
      <div class="alert-body">
        <strong>
          <feather-icon icon="BookIcon" class="mr-25" />
          <b-spinner type="grow" small />
          <b-spinner type="grow" small />
          <b-spinner type="grow" small />
          QRCODE KHUSUS Default QR Auto Renew <b-badge variant="success"> ON </b-badge>
          <b-spinner type="grow" small />
          <b-spinner type="grow" small />
          <b-spinner type="grow" small />
        </strong>
      </div>
    </b-alert>
    <b-card>
      <YoGoodTable
        :fields="tableColumns"
        :data="tableData"
        :total="totalItems"
        :currentPage="currentPage"
        :perPage="perPage"
        @page-changed="handlePageChange"
        @per-page-changed="handlePerPageChange"
        @edit="handleEdit"
        @delete="handleDelete"
        @update-is-active="updateIsActive"
      />
      <div class="divider">
        <div class="divider-text"></div>
      </div>
      <feather-icon icon="DatabaseIcon" class="mr-25" />
      <span>Halaman hanya dapat dilihat oleh : [manage all, visitor-khusus]</span>
    </b-card>
    <b-alert :variant="'primary'" show class="cursor-pointer">
      <div class="alert-body">
        <strong>
          <feather-icon icon="BookIcon" class="mr-25" />
          <b-spinner type="grow" small />
          <b-spinner type="grow" small />
          <b-spinner type="grow" small />
          QRCODE KHUSUS
          <b-spinner type="grow" small />
          <b-spinner type="grow" small />
          <b-spinner type="grow" small />
        </strong>
      </div>
    </b-alert>
  </div>
</template>

<script>
import {
  BCard,
  BSpinner,
  BAlert,
  BBadge,
} from 'bootstrap-vue'
import YoGoodTable from '@/views/components/YoGoodTableQRControll.vue'
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'

export default {
  components: {
    BCard,
    BSpinner,
    BAlert,
    YoGoodTable,
    BBadge,
  },
  data() {
    return {
      /* good table */
      totalItems: 0, // Total number of items from API
      currentPage: 1, // Current page number
      perPage: 10, // Items per page
      apiBaseUrl: 'visitor_private', // API base URL

      tableColumns: [
        { label: 'Badge', field: 'badge', width: '350px' },
        { label: 'QR Mode', field: 'is_active', width: '180px' },
        { label: 'Nama / Whatsapp', field: 'full_name', width: '200px' },
        // { label: 'Asal / Status', field: 'status', width: '120px' },
        { label: 'Last Time ON', field: 'last_in', width: '250px' },
        { label: 'ID', field: 'id', width: '60px' }
      ],
      tableData: [

      ],
      badgeTitle: {
        1: '[ A.1 ] Tamu ( Visitor ) - Umum',
        2: '[ A.2 ] Awak 2 Truck',
        3: '[ B ] Identitas / Karyawan / Rutinitas',
        4: '[ C ] Pekerja Harian' ,
        5: '[ D ] Pengurus Agent BBM' ,
      }
    }
  },
  computed: {},
  mounted() {
    this.fetchData() // Fetch initial data
  },
  created() {},
  methods: {
    moment,
    // Fetch data from API
    async fetchData(page = 1, size = 10) {
      try {
        // Make API request using useJwt
        const response = await useJwt.http.get(
          `${this.apiBaseUrl}?page=${page - 1}&size=${size}`
        )

        // Assuming response.data contains the actual data
        const data = response.data

        if (data.success) {
          // Assign the fetched data to the table
          this.tableData = data.visitor_qrcodes.map((visitor) => ({
            id: visitor.id,
            badge: `${this.badgeTitle[Number(visitor.visitor_private.badge_id)]}`,
            nomor_whatsapp: visitor.visitor_private.phone,
            ktp: visitor.visitor_private.no_ktp,
            full_name: visitor.visitor_private.full_name,
            alamat: visitor.visitor_private.address,
            perusahaan: visitor.visitor_private.from_company,
            last_in:
            visitor.last_in
              ? moment(visitor.last_in, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMMM YYYY  HH:mm')
              : 'Not Available',
            last_out: visitor.last_out
              ? new Date(visitor.last_out).toLocaleString()
              : 'Not Available',
            is_active: visitor.is_active,
            status: visitor.status,
            sys_auto_renew: visitor.sys_auto_renew,
            qrcode_link: visitor.qrcode_link
          }))
          this.totalItems = data.total_items
          this.currentPage = data.current_page + 1 // Adjust for 0-based index
          this.perPage = size
        } else {
          console.error('Failed to fetch data: Invalid success flag')
        }
      } catch (error) {
        console.error('Failed to fetch data:', error)
      }
    },

    // Handle page change event from YoGoodTable
    handlePageChange(newPage) {
      this.fetchData(newPage, this.perPage)
    },

    // Handle per page change event from YoGoodTable
    handlePerPageChange(newPerPage) {
      this.fetchData(this.currentPage, newPerPage)
    },

    handleEdit(row) {
      // Edit logic here
    },
    handleDelete(row) {
      // Delete logic here
    },
    updateIsActive(item, state) {
      const value = !item.is_active
      try {
        console.log('item', item)
        const dataItem = this.tableData.find((d) => d.id === item.id)

        useJwt.http
          .put(`visitor_qrcode/${state}/${item.id}`, {
            value: value
          })
          .then((res) => {
            // Update the local data
            this.$set(dataItem, 'is_active', value)
            this.$alert({
              title: res.data.message,
              variant: 'success',
              icon: 'CheckIcon'
            })
          })
          .catch((err) => {
            this.$set(dataItem, 'is_active', !value)
            console.error('Error updating status:', err)
          })
      } catch (error) {
        console.error('Error updating is_active:', error)
        this.$set(dataItem, 'is_active', !value)
      }
    }
  }
}
</script>
